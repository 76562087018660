import { jwtDecode } from "jwt-decode";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMemo } from "react";
import { createContext } from "react";
import { useLocation } from "react-router-dom";
import { io } from "socket.io-client";

const SocketContext = createContext(null);

export const useSocket = () => {
    return useContext(SocketContext)
}

export const SocketProvider = (props) => {
    const [isLoading, setInloading] = useState(false)
    const token = JSON.parse(sessionStorage.getItem("data"))
    const decodedToken = jwtDecode(token);

    const socket = useMemo(() => {
        return decodedToken ? io("https://dev.rezeet.io") : false // Add return statement here
    }, []);

    useEffect(() => {
        setInloading(true)
        socket.on("connect", () => {
            console.log("Connected to Server.")
        })
        return () => {
            socket.disconnect();
            socket.off("connect")
        };
    }, [])

    useMemo(() => {
        socket.emit("update_socket_id", { userId: decodedToken?._id })
        socket.on("update_socket_id", (data) => {
            console.log("Merchant Id Update Sucessfully.")
            if (data) {
                setInloading(false)
            }
        })
        return () => {
            socket.disconnect();
            socket.off("update_socket_id")
        };
    }, [])


    return (
        <SocketContext.Provider value={{ socket }}>
            {props.children}
            {/* {isLoading ? document.body.classList.add('loading-indicator') : document.body.classList.remove('loading-indicator')} */}
        </SocketContext.Provider>
    );
};


// export const SocketProvider = (props) => {
//     const socket = useMemo(() => {
//         io("https://dev.rezeet.io")
//     }, [])
//     return (
//         <SocketContext.Provider value={{ socket }}>
//             {props.children}
//         </SocketContext.Provider>
//     )
// }