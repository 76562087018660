import React from 'react'

export const ButtonCustome = (props) => {
  const {
    className = '',
    buttonTitle = '',
    type = 'button',
    onClick,
    isLoading,
    dislabled
  } = props
  return <button dislabled={dislabled ? dislabled : ''} onClick={onClick} type={type ? type : ''} className={className ? className : ''}>
    {
      isLoading ? (
        <div className='flex items-center'>
          <span className="loading loading-spinner loading-xs"></span>
          <span className='ml-2'>Processing...</span>
        </div>

      ) : (buttonTitle)
    }
    {/* {buttonTitle ? buttonTitle : ''} */}
  </button>
}
