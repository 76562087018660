export const image = {
    logo : require("../assets/images/logos/logo.png"),
    // headerLogo : require("../assets/images/headerLogo/logo1x.png"),
    searchIcon : require("../assets/images/searchIcon/searchIcon.gif"),
    product01 : require("../assets/images/ProductImages/product01.png"),
    imageUpload : require("../assets/images/searchIcon/imageUpload.png"),
    needHelp : require("../assets/images/searchIcon/needHelp.png"),
    dubbing_img : require("../assets/images/searchIcon/dubbing_img.png"),
    profile_image : require("../assets/images/profile_image/man.jpg"),
    Login_Side01 : require("../assets/images/logos/Login_Side01.png"),
    Group1 : require("../assets/images/searchIcon/Group1.png"),
    Group2 : require("../assets/images/searchIcon/Group2.png"),
    Group3 : require("../assets/images/searchIcon/Group3.png"),
    Group4 : require("../assets/images/searchIcon/Group4.png"),
    button_img : require("../assets/images/searchIcon/button_img.png"),
    emogi : require("../assets/images/img/emogi.gif"),
    driving_licensefront : require("../assets/images/img/driving_license.png"),
    driving_licenseback : require("../assets/images/img/driving_license2.png"),
}